<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('message.viewchat_messages')"></h1>
      <p>{{ $t('message.viewchat_messages_intro') }}</p>
    </div>
    <div class="smenu">
      <div class="chat_text" v-html="$t('message.viewchat_messages_info', {total_count: messageList.length, unread_count: unreadList.length})"></div>
      <div class="chat_btn">
        <a href="javascript:" @click="onReadAll">{{ $t('message.message_10') }}</a>
        <a href="javascript:" @click="onDeleteAll">{{ $t('message.message_09') }}</a>
      </div>
    </div>

    <div class="warp">
      <div class="chat_list">
        <ul>
          <li v-for="message in messageList" :key="message.user.uid" @click="onCell(message)">
            <UserImage :gender="message.user.sex" :image="message.user.profileImage"/>
            <div class="chat_text">
              <p>{{ message.user.nickname }}<span :class="[{male: message.user.sex == 0, female: message.user.sex == 1}]">({{(message.user.sex == 1 ? $t('common.female_short') : $t('common.male_short')) + message.user.age}})</span><i v-if="message.message.isSent == 0 && message.message.status == 1">N</i></p>
              <h2>{{ getLastMessageText(message.message) }}</h2>
            </div>
            <div class="time">{{ getRelativeDate(message.message.time) }}</div>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./Chat.scss"></style>

<script>
import dao from "../../lib/dao";
import { EVENT_NAME, OFFLINE_MODE } from "../../lib/constant";
import UserImage from "@/components/UserImage";
import moment from "moment";
import { setMomentLocale } from "../../lib/util";

export default {
  name: 'Chat',
  components: {
    UserImage,
  },
  data() {
    return {
      myInfo: this.$store.getters.me,
      messageList: [],
    }
  },
  mounted() {
    this.initEvent();
    this.getList();
  },
  computed: {
    unreadList() {
      return this.messageList.filter(message => {
        return message.message.isSent == 0 && message.message.status == 1;
      });
    }
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.NEW_MESSAGE_TEXT, this.onNewMessageText);
    this.emitter.off(EVENT_NAME.NEW_MESSAGE_IMAGE, this.onNewMessageImage);
    this.emitter.off(EVENT_NAME.MESSAGE_DETAIL_BACK, this.getList);
    this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.getList);
    this.emitter.off(EVENT_NAME.CHECK_DB, this.getList);
  },
  methods: {
    getRelativeDate: function (time) {
      setMomentLocale();
      return moment(time).fromNow()
    },
    getLastMessageText: function (message) {
      switch (message.type) {
        case 'image':
          return this.$t('message.message_sent_photo');
        case 'gift_item_sent':
        case 'gift_item_sent_multi':
        case 'gift_item_sent_in_video':
          return this.$t('message.message_sent_gift');
        default:
          return message.message;
      }
    },
    onReadAll: function () {
      this.$root.showConfirmDlg(
        '',
        this.$t('message.message_read_confirm'),
        this.$t('common.ok'),
        this.$t('common.cancel'),
        () => {
          dao.readAllMessages();
          dao.readAllVideoCalls();
          dao.readAllGiftItems();
          this.getList();
        }
      );
    },
    onDeleteAll: function () {
      this.$root.showConfirmDlg(
        '',
        this.$t('message.message_delete_confirm'),
        this.$t('common.ok'),
        this.$t('common.cancel'),
        () => {
          dao.deleteAllMessages();
          dao.deleteAllVideoCalls();
          dao.deleteAllGiftItems();
          this.getList();
        }
      );
    },
    onCell: function (message) {
      dao.readAllMessagesWithUser(message.user.uid);
      dao.readAllVideoCallsWithUser(message.user.uid);
      dao.readAllGiftItemsWithUser(message.user.uid);
      this.$root.showMessageDetailDlg(dao.fromDaoEntity(message.user));
    },
    onImage: function (url) {
      /*if (url != '') {
        window.open(url, '_blank');
      }*/
    },
    getList: async function () {
      if (OFFLINE_MODE) {
        this.messageList = [...Array(15).keys()].map(idx => {
          return {
            message: {
              isSent: 0,
              status: 0,
              type: 'text',
              message: 'Hi, I am message content.',
            },
            user: {
              uid: idx,
              sex: idx % 2,
              profileImage: '',
              age: 20 + idx,
              nickname: '홍길동' + idx,
            }
          }
        });

        return;
      }

      if (dao.db) {
        this.messageList = await dao.getRecentMessages();
      }
    },
    initEvent: function () {
      this.emitter.on(EVENT_NAME.NEW_MESSAGE_TEXT, this.onNewMessageText);
      this.emitter.on(EVENT_NAME.NEW_MESSAGE_IMAGE, this.onNewMessageImage);
      this.emitter.on(EVENT_NAME.MESSAGE_DETAIL_BACK, this.getList);
      this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.getList);
      this.emitter.on(EVENT_NAME.CHECK_DB, this.getList);
    },
    onNewMessageText: function (packet) {
      if (packet.content == '###REMOVE_MESSAGE_CONTENT###') {
        return;
      }

      this.getList();
    },
    onNewMessageImage: function () {
      this.getList();
    },
  }
}
</script>
